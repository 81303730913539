/** @jsx jsx */
import { jsx, Link, useThemeUI } from "theme-ui"
import { readableColor } from "polished"
import { BLUE, PRIMARY_GREEN } from '../../../colors'
import { PREFIX } from '../../../constants'

const Footer = ({ bg }: { bg: string }) => {
  const {
    theme: { rawColors },
  } = useThemeUI()

  const text = readableColor(
    bg,
    rawColors!.textMuted as string | undefined,
    rawColors!.textMutedLight as string | undefined
  )

  return (
    <footer
      sx={{
        position: [`relative`, `relative`, `relative`, `fixed`],
        width: (t: any): any => [`100%`, `100%`, `100%`, t.sidebar.normal, t.sidebar.wide],
        bottom: 0,
        color: 'white',
        fontSize: 0,
        p: [3, 3, 4],
        background: PRIMARY_GREEN,
        a: {
          textDecoration: 'none',
          color: 'white',
          "&:hover,&:focus": {
            // color: readableColor(bg, `primary`, `primaryLight`, false),
            color: BLUE,
            textDecoration: 'none'
          },

        },
        variant: `footer`,
      }}
    >
      <div>
        <a href={`${PREFIX}/impressum`}>Impressum</a>
      </div>

    </footer>
  )
}

export default Footer
