/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { readableColor } from "polished"
import Navigation from '@lekoarts/gatsby-theme-jodie/src/components/navigation'
import { PRIMARY_GREEN } from '../../../colors'
import Logo from '@lekoarts/gatsby-theme-jodie/src/icons/logo'
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import { PREFIX } from '../../../constants'


const Sidebar = () => {
  const bg = PRIMARY_GREEN;
  return (
    <header
      sx={{
        p: [3, 3, 4],
        width: (t: any): any => [`100vw`, `100vw`, `100vw`, t.sidebar.normal, t.sidebar.wide],
        background: bg,
        position: [`relative`, `relative`, `relative`, `fixed`],
        height: `100%`,
        display: `flex`,
        flexWrap: 'wrap',
        flexDirection: [`row`, `row`, `row`, `column`],
        alignItems: [`center`, `center`, `center`, `flex-start`],
        justifyContent: [`center`, `space-between`, `space-between`, `flex-start`],
        svg: {
          fill: readableColor(bg),
        },
        variant: `sidebar`,
      }}
      data-testid="sidebar"
    >
      <Link to="/" aria-label={`${'Viktor Hermeler'}, Back to Home`} sx={{ width: [`60%`, `36%`, `36%`, `100%`] }}>
        <img src={`${PREFIX}/logo_right.png`} style={{ width: '100%' }} />

      </Link>
      <div sx={{ py: 4, display: [`none`, `none`, `none`, `block`] }} />
      <Navigation bg={bg} />
    </header>
  )
}

export default Sidebar